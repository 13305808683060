import React from 'react';
import styled from 'styled-components';

interface AccordionSectionProps {
  items: { title: string; content: string }[];
  activeIndex: number | null; // Ensure this can be null
  startIndex: number; // To distinguish items if multiple sections are used
  onToggle: (index: number) => void; // Callback to handle section toggle
}

const AccordionSection: React.FC<AccordionSectionProps> = ({ items, activeIndex, startIndex, onToggle }) => {
  return (
    <AccordionContainer>
      {items.map((item, idx) => {
        const index = startIndex + idx; // Calculate the index based on the starting index
        return (
          <AccordionItem key={index}>
            <AccordionHeader onClick={() => onToggle(index)}>
              <AccordionTitle>{item.title}</AccordionTitle>
              <AccordionIcon>
                {activeIndex === index ? '−' : '+'}
              </AccordionIcon>
            </AccordionHeader>
            {activeIndex === index && (
              <AccordionBody>{item.content}</AccordionBody>
            )}
          </AccordionItem>
        );
      })}
    </AccordionContainer>
  );
};

const AccordionContainer = styled.div`
  background-color: white;
`;

const AccordionItem = styled.div`
  margin-bottom: 1rem;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #e9e9e9;
  }
`;

const AccordionTitle = styled.span`
  flex-grow: 1;
`;

const AccordionIcon = styled.span`
  font-size: 1.5rem;
`;

const AccordionBody = styled.div`
  padding: 1rem;
  border-top: 1px solid #ddd;
`;

export default AccordionSection;
