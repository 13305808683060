import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Container, Spinner } from 'react-bootstrap';
import { useAppSelector } from './hooks/useLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import DOMPurify from 'dompurify';
import useViewport from './hooks/useViewport';
import PhoneFooter from './PhoneFooter';

interface ProductSpecifications {
    [key: string]: string | number;
}

interface Product {
    id: number;
    productCategory: string;
    productCategoryId: string;
    productSubCategoryId: string;
    productSubCategory: string;
    productName: string;
    productCode: string;
    productDescription: string;
    measurementDetails: string;
    applications: string;
    warranty: string;
    pricePerUnit: string;
    unitName: string;
    quantity: string;
    image: string;
    adminEmail: string;
    adminName: string;
    createdAt: string;
    updatedAt: string;
    specifications?: ProductSpecifications;
}

export default function Product() {
    const { categoryId } = useParams<{ categoryId?: string }>();
    const [product, setProduct] = useState<Product | null>(null);
    const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(false);
    const [productError, setProductError] = useState<string | null>(null);
    const { email } = useAppSelector((state) => state.auth);
    const [visibleProducts, setVisibleProducts] = useState(4);
    const [quantity, setQuantity] = useState<number | null>(1);
    const navigate = useNavigate();

    const viewportWidth = useViewport();
    const isMobile = viewportWidth < 768;

    useEffect(() => {
        if (categoryId) {
            fetchProductDetails(categoryId);
            fetchRelatedProducts(categoryId);
        } else {
            setProductError('Product ID is missing.');
        }
    }, [categoryId]);

    const fetchProductDetails = async (productId: string) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/user/viewProduct/${productId}`);
            setProduct(response.data.products);
        } catch (err) {
            setProductError('Error fetching product details.');
        } finally {
            setLoading(false);
        }
    };

    const fetchRelatedProducts = async (productId: string) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/user/related-products/${productId}`);
            setRelatedProducts(response.data.products);
        } catch (err) {
            console.error('Error fetching related products:', err);
        }
    };

    const loadMoreProducts = () => {
        setVisibleProducts(prevVisible => prevVisible + 4);
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue) && parsedValue > 0) {
            setQuantity(parsedValue);
        } else if (value === '') {
            setQuantity(null);
        }
    };

    const handleAddToCart = async () => {
        if (!email) {
            alert('Please log in to add items to your cart.');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/user/add-to-cart`, {
                email,
                productId: product?.id,
                quantity,
            });

            if (response) {
                navigate('/cart');
            } else {
                alert('Failed to add the product to the cart. Please try again.');
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            alert('Failed to add the product to the cart. Please try again.');
        }
    };

    if (loading) return <Spinner animation="border" variant="primary" />;
    if (productError) return <p className="text-danger">{productError}</p>;

    const getResponsiveStyles = (viewportWidth: number) => {
        if (viewportWidth < 768) {
            return {
                containerStyle: {
                    padding: '1rem',
                },
                productImageStyle: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                productNameStyle: {
                    fontSize: '1.2rem',
                },
                productPriceStyle: {
                    fontSize: '1.4rem',
                },
                tableStyle: {
                    fontSize: '0.9rem',
                }
            };
        }
        return {
            containerStyle: {
                padding: '2rem',
            },
            productImageStyle: {
                maxWidth: '400px',
            },
            productNameStyle: {
                fontSize: '2rem',
            },
            productPriceStyle: {
                fontSize: '1.6rem',
            },
            tableStyle: {
                fontSize: '1rem',
            }
        };
    };

    const {
        containerStyle,
        productImageStyle,
        productNameStyle,
        productPriceStyle,
        tableStyle,
    } = getResponsiveStyles(viewportWidth);

    return (
        <>
            <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
            <div style={{ padding: '1rem 10%', backgroundColor: '#f8f9fa' }}>
                <h6>
                    <FontAwesomeIcon icon={faHouse} /> Home / {product?.productCategory || 'Category'} / {product?.productName || 'Product'}
                </h6>
            </div>
            <Container className="my-4" style={containerStyle}>
                <div className="product-container" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '1rem' }}>
                    {product && (
                        <>
                            <div className="product-image-container" style={{ flex: 1 }}>
                                <img
                                    src={`${API_BASE_URL_IMAGE}/${product.image}`}
                                    alt={product.productName}
                                    style={productImageStyle}
                                />
                            </div>
                            <div className="product-info" style={{ flex: 2, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <h3 style={productNameStyle}>{product.productName}</h3>
                                <p className={Number(product?.quantity) > 0 ? 'in-stock' : 'out-of-stock'} style={{ color: Number(product?.quantity) > 0 ? '#28a745' : '#dc3545' }}>
                                    {Number(product?.quantity) > 0 ? 'In Stock' : 'Stock Out'}
                                </p>
                                <h4 className="product-price" style={productPriceStyle}>
                                    Current price: <span>{product.pricePerUnit}</span> BDT
                                </h4>
                                <div className="quantity-selector" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    <label htmlFor="quantity">Quantity:</label>
                                    <input
                                            type="number"
                                            id="quantity"
                                            name="quantity"
                                            value={quantity === null ? '' : quantity}
                                            onChange={handleQuantityChange}
                                            min="1"
                                            className="form-control w-25"
                                        />
                                </div>
                                {Number(product?.quantity) > 0 && (
                                    <Button onClick={handleAddToCart} className="btn-add-to-cart" style={{ backgroundColor: '#007bff', borderColor: '#007bff', color: '#fff' }}>
                                        Add to Cart
                                    </Button>
                                )}


                                {!isMobile && ( // Only render on non-mobile views
                                    <div
                                        className="product-description"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.productDescription) }}
                                        style={{ border: '1px solid #ddd', padding: '1rem', borderRadius: '8px' }}
                                    />
                                )}


                            </div>
                        </>
                    )}
                </div>
            </Container>

            <div style={{ padding: '1rem 10%' }}>
                <h4 style={{ borderBottom: '2px solid #007bff', paddingBottom: '0.5rem' }}>Specification</h4>
                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '1rem', fontSize: tableStyle.fontSize }}>
                    <tbody>
                        {product && (
                            <>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Product Name</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.productName)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Product Category</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.productCategory)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Product Sub Category</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.productSubCategory)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Product Code</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.productCode)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Product Description</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(product.productDescription),
                                        }} />
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Measurement Details</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(product.measurementDetails),
                                        }} />
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Applications</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(product.applications),
                                        }} />
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Warranty</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.warranty)} Months</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Price Per Unit</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.pricePerUnit)}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem', fontWeight: 'bold' }}>Unit Name</td>
                                    <td style={{ border: '1px solid #ddd', padding: '0.5rem' }}>{String(product.unitName)}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>

            <div style={{ padding: '1rem 10%', backgroundColor: '#f8f9fa' }}>
                <div className="row">
                    <div className="col-md-6">
                        <div style={{ textAlign: 'left', padding: '1rem', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                            <h4 style={{ fontWeight: 'bold', borderBottom: '2px solid #007bff', paddingBottom: '0.5rem' }}>
                                Return & Warranty
                            </h4>
                            <h5>Cancellation Before Shipment:</h5>
                            <ul>
                                <li>You can cancel your order within 24 hours of placing it, provided it has not been shipped yet.</li>
                                <li>To cancel, log in to your Interio account, go to your order history, select the order you wish to cancel, and follow the prompts.</li>
                                <li>Confirmation: You will receive an email confirming the cancellation. Payments will be refunded within 7-10 business days.</li>
                            </ul>
                            <h5>Cancellation After Shipment:</h5>
                            <ul>
                                <li>If the order has already been shipped, it cannot be canceled. You may return the product after delivery.</li>
                                <li>If you refuse delivery, return shipping fees will be deducted from your refund.</li>
                            </ul>
                            <h5>Custom or Made-to-Order Products:</h5>
                            <ul>
                                <li>Custom orders cannot be canceled once production has started. Contact customer service for changes as soon as possible.</li>
                            </ul>
                            <h5>Order Cancellation by Interio:</h5>
                            <ul>
                                <li>Interio reserves the right to cancel orders due to stock issues, payment problems, or policy violations.</li>
                                <li>If canceled by Interio, you will be notified via email and refunded to your original payment method.</li>
                            </ul>
                            <h5>Refund Processing:</h5>
                            <ul>
                                <li>Refunds are processed within 7-10 business days. Additional time may be required for the refund to appear in your account.</li>
                                <li>Refunds will be issued to the original payment method. If paid by credit card, the refund will appear on your statement.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h4
                            className="related-header"
                            style={{
                                fontWeight: 'bold',
                                borderBottom: '3px solid #007bff',
                                paddingBottom: '0.5rem',
                                marginBottom: '1rem',
                                fontSize: '1.5rem',
                                textAlign: 'center'
                            }}
                        >
                            Related Products
                        </h4>
                        <div
                            className="row"
                            style={{
                                marginLeft: '0',
                                marginRight: '0'
                            }}
                        >
                            {relatedProducts.slice(0, visibleProducts).map((product) => (
                                <div
                                    key={product.id}
                                    className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4" // Two products in a row on desktop, one product on mobile
                                    style={{
                                        padding: '0.5rem',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div
                                        className="card"
                                        style={{
                                            border: 'none',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                            transition: 'transform 0.2s, box-shadow 0.2s',
                                            marginBottom: '1rem',
                                            height: '100%' // Make cards equal height
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
                                        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                    >
                                        <img
                                            src={`${API_BASE_URL_IMAGE}/${product.image}`}
                                            alt={product.productName}
                                            style={{
                                                width: '100%',
                                                height: '200px',
                                                objectFit: 'cover',
                                                transition: 'transform 0.2s',
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                        />
                                        <div
                                            className="card-body"
                                            style={{
                                                padding: '1rem',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <h5
                                                className="card-title"
                                                style={{
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    margin: '0.5rem 0'
                                                }}
                                            >
                                                {product.productName}
                                            </h5>
                                            <p
                                                className="card-price"
                                                style={{
                                                    color: '#007bff',
                                                    fontSize: '0.9rem',
                                                    margin: '0.5rem 0'
                                                }}
                                            >
                                                {product.pricePerUnit} BDT
                                            </p>
                                            <Link
                                                to={`/product-details/${product.id}`}
                                                className="btn btn-primary"
                                                style={{
                                                    textDecoration: 'none',
                                                    backgroundColor: '#007bff',
                                                    borderColor: '#007bff',
                                                    color: '#fff',
                                                    padding: '0.5rem 1.5rem',
                                                    borderRadius: '5px',
                                                    fontSize: '0.9rem',
                                                    display: 'inline-block',
                                                    transition: 'background-color 0.2s, color 0.2s'
                                                }}
                                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                            >
                                                See Details
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {relatedProducts.length > visibleProducts && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '1rem'
                                }}
                            >
                                <Button
                                    onClick={loadMoreProducts}
                                    className="btn-load-more"
                                    style={{
                                        backgroundColor: '#007bff',
                                        borderColor: '#007bff',
                                        color: '#fff',
                                        padding: '0.5rem 1.5rem',
                                        borderRadius: '5px',
                                        fontSize: '1rem',
                                        transition: 'background-color 0.2s',
                                        display: 'inline-block'
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                >
                                    Load More
                                </Button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <Footer />
            {isMobile && <PhoneFooter isLoggedIn={!!email} userHomeLink="/user-home" />}
        </>
    );
}
