import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure you have axios installed
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import { useAppSelector } from './hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Product {
  id: number;
  productName: string;
  pricePerUnit: string;
  image: string;
  productDescription: string;
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled.div`
  flex: 1 1 calc(50% - 1rem); /* 2 items per row on small screens */
  min-width: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* Responsive adjustments */
  @media (min-width: 769px) {
    flex: 1 1 calc(25% - 1rem); /* 4 items per row on larger screens */
    max-width: 300px; /* Set a maximum width for cards */
  }
`;

const Img = styled.img`
  width: 100%;
  height: 200px; /* Set fixed height for images */
  object-fit: cover;
  transition: opacity 0.3s ease;
  border-bottom: 2px solid #eee;
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.h5`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const CardPrice = styled.p`
  font-size: 1.125rem;
  color: #f39c12;
  margin-bottom: 1rem;
`;

const LoadMoreBtn = styled.button`
  border: 2px solid orange;
  color: orange;
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: orange;
    color: white;
  }
`;

const TextCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default function JustForYou() {
  const { email } = useAppSelector((state) => state.auth);

  const [products, setProducts] = useState<Product[]>([]);
  const [visibleProducts, setVisibleProducts] = useState(4);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // First, fetch products from the "just-for-you" API
        const response = await axios.get(`${API_BASE_URL}/user/just-for-you/${email}`);
        const fetchedProducts = response.data.products; // Adjust based on your API response
        
        if (fetchedProducts.length === 0) {
          // If "just-for-you" is empty, fetch from the "admin/products" API
          const fallbackResponse = await axios.get(`${API_BASE_URL}/admin/products`);
          setProducts(fallbackResponse.data.products.slice(0, 10)); // Limit to 10 products
        } else {
          setProducts(fetchedProducts);
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products.');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [email]);

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 4);
  };

  const handleProductDetails = (productId: number) => {
    navigate(`/product-details/${productId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container>
      <h4 className="mb-4">Just For You</h4>
      <Row>
        {products.slice(0, visibleProducts).map((product) => (
          <Card key={product.id} onClick={() => handleProductDetails(product.id)}>
            <Img
              src={`${API_BASE_URL_IMAGE}/${product.image}`}
              alt={product.productName}
            />
            <CardBody>
              <CardTitle>{product.productName}</CardTitle>
              <CardPrice>৳{product.pricePerUnit}</CardPrice>
              
            </CardBody>
            <LoadMoreBtn
              onClick={() => handleProductDetails(product.id)}
            >
              See Details
            </LoadMoreBtn>
          </Card>
        ))}
      </Row>
      {visibleProducts < products.length && (
        <TextCenter>
          <LoadMoreBtn onClick={loadMoreProducts}>
            Load More
          </LoadMoreBtn>
        </TextCenter>
      )}
    </Container>
  );
}
