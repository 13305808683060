import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';

export interface Product {
  id: number;
  productCategory: string;
  productCategoryId: string;
  productSubCategoryId: string;
  productSubCategory: string;
  productName: string;
  productCode: string;
  productDescription: string;
  measurementDetails: string;
  applications: string;
  warranty: string;
  pricePerUnit: string;
  unitName: string;
  quantity: string;
  image: string;
  adminEmail: string;
  adminName: string;
  createdAt: string;
  updatedAt: string;
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled.div`
  flex: 1 1 calc(50% - 1rem); /* 2 items per row on small screens */
  min-width: 100px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* Responsive adjustments */
  @media (min-width: 769px) {
    flex: 1 1 calc(25% - 1rem); /* 4 items per row on larger screens */
    max-width: 300px; /* Set a maximum width for cards */

  }
`;

const Img = styled.img`
  width: 100%;
  height: 200px; /* Set fixed height for images */
  object-fit: cover;
  transition: opacity 0.3s ease;
  border-bottom: 2px solid #eee;
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.h5`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const CardPrice = styled.p`
  font-size: 1.125rem;
  color: #f39c12;
  margin-bottom: 1rem;
`;

const LoadMoreBtn = styled.button`
  border: 2px solid orange;
  color: orange;
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: orange;
    color: white;
  }
`;

const TextCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export default function BestSellingProducts() {
  const [products, setProducts] = useState<Product[]>([]);
  const [fallbackProducts, setFallbackProducts] = useState<Product[]>([]);
  const [visibleProducts, setVisibleProducts] = useState(4);
  const [loadingFallback, setLoadingFallback] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get<{ products: Product[] }>(`${API_BASE_URL}/user/best-selling`);
        if (response.data.products.length === 0) {
          setLoadingFallback(true);
          const fallbackResponse = await axios.get<{ products: Product[] }>(`${API_BASE_URL}/admin/products`);
          setFallbackProducts(fallbackResponse.data.products);
          setLoadingFallback(false);
        } else {
          setProducts(response.data.products);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingFallback(true);
        try {
          const fallbackResponse = await axios.get<{ products: Product[] }>(`${API_BASE_URL}/admin/products`);
          setFallbackProducts(fallbackResponse.data.products);
        } catch (fallbackError) {
          console.error('Error fetching fallback products:', fallbackError);
        }
        setLoadingFallback(false);
      }
    };

    fetchProducts();
  }, []);

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 4);
  };

  const handleProductDetails = (productId: number) => {
    navigate(`/product-details/${productId}`);
  };

  const displayedProducts = products.length > 0 ? products : fallbackProducts;

  return (
    <Container>
      <h4 className="mb-4">Best Selling Products</h4>

      <Row >
        {displayedProducts.slice(0, visibleProducts).map((product) => (
          <Card key={product.id} onClick={() => handleProductDetails(product.id)}>
            <Img
              src={`${API_BASE_URL_IMAGE}/${product.image}`}
              alt={product.productName}
            />
            <CardBody>
              <CardTitle>{product.productName}</CardTitle>
              <CardPrice>৳{product.pricePerUnit}</CardPrice>
            </CardBody>
            <LoadMoreBtn
              onClick={() => handleProductDetails(product.id)}
            >
              See Details
            </LoadMoreBtn>
          </Card>
        ))}
      </Row>
      {displayedProducts.length > visibleProducts && (
        <TextCenter>
          <LoadMoreBtn onClick={loadMoreProducts}>
            Load More
          </LoadMoreBtn>
        </TextCenter>
      )}
    </Container>
  );
}
