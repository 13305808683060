import Footer from './Footer';
import AllCategories from './AllCategories';
import WhyChooseInterio from './WhyChooseInterio';
import BestSellingProducts from './BestSellingProducts';
import Steps from './Steps';
import HeroSection from './HeroSection';
import { useAppSelector } from './hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from './Navbar';
import PhoneFooter from './PhoneFooter';
import useViewport from './hooks/useViewport'; // Import the custom hook

export default function Landing() {
  const { email } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const viewportWidth = useViewport(); // Get the viewport width

  const isMobile = viewportWidth < 768;
  useEffect(() => {
    if (email) {
      navigate('/user-home');
    }
  }, [email, navigate]);



  return (
    <div>
      <Navbar
        isLoggedIn={false}
        userHomeLink="/user-home"
      />

      <HeroSection />
      <AllCategories />


      <BestSellingProducts />
      <WhyChooseInterio />


      <Steps />
      <Footer />
      <br></br>
      <br></br>

      {isMobile && <PhoneFooter isLoggedIn={false} userHomeLink={'/'} />}

    </div>
  );
}
