import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSubCategoriesByCategory } from './api/categoryApi'; // Adjust the import path as needed
import '../assets/css/categories.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Navbar from './Navbar';
import Footer from './Footer';
import Categories from './Categories';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import axios from 'axios';
import { useAppSelector } from './hooks/useLogin';
import { Row, Card, CardBody, CardTitle } from 'react-bootstrap';
import styled from 'styled-components';

// Define a type for subCategory based on expected API response
interface SubCategory {
    id: string;
    name: string;
    image: string;
    cname?: string; // optional if not always present
}


export default function SubCategories() {
    const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory | null>(null);
    const [displayingProducts, setDisplayingProducts] = useState<boolean>(false);
    const navigate = useNavigate();
    const { categoryId } = useParams<{ categoryId: string }>();
    const { data: subCategories, isLoading, error } = useGetSubCategoriesByCategory(categoryId || '');
    const [loading, setLoading] = useState(false);
    const [productError, setProductError] = useState<string | null>(null);
    const [products, setProducts] = useState<any[]>([]);
    const { email } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (!subCategories || subCategories.length === 0) {
            fetchProduct(categoryId || '');
        }
    }, [subCategories, categoryId]);

    const fetchProduct = async (Id: string) => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/user/products/${Id}`);
            setProducts(response.data.product); // Assuming the response is an array of products
        } catch (err) {

        } finally {
            setLoading(false);
        }
    };

    const handleSeeAllClick = async (subCategory: SubCategory) => {
        setSelectedSubCategory(subCategory);
        setDisplayingProducts(true);
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/user/productSubCategoryId/${subCategory.id}`);
            setProducts(response.data.products);
            setProductError(null);
        } catch (err) {
            setProductError('Error fetching products for the selected subcategory.');
        } finally {
            setLoading(false);
        }
    };

    const handleProductDetails = (productId: string) => {
        navigate(`/product-details/${productId}`);
    };
    const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    margin-top: 1rem;
  `;

    const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  `;

    const Card = styled.div`
    flex: 1 1 calc(50% - 1rem); /* 2 items per row on small screens */
    min-width: 100px;
  
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    /* Responsive adjustments */
    @media (min-width: 769px) {
      flex: 1 1 calc(25% - 1rem); /* 4 items per row on larger screens */
      max-width: 300px; /* Set a maximum width for cards */
  
    }
  `;

    const Img = styled.img`
    width: 100%;
    height: 200px; /* Set fixed height for images */
    object-fit: cover;
    transition: opacity 0.3s ease;
    border-bottom: 2px solid #eee;
  `;

    const CardBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

    const CardTitle = styled.h5`
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
  `;

    const CardPrice = styled.p`
    font-size: 1.125rem;
    color: #f39c12;
    margin-bottom: 1rem;
  `;

    const LoadMoreBtn = styled.button`
    border: 2px solid orange;
    color: orange;
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 4px;
  
    &:hover {
      background-color: orange;
      color: white;
    }
  `;

    const TextCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  `;
    if (isLoading || loading) return <p>Loading...</p>;
    if (error) return <p>Error loading subcategories: {error.message}</p>;

    return (
        <div>

            <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />

            <div style={{ background: '#F1F1F1' }}>
                <h6 style={{ paddingLeft: '8%' }} className="mb-4">
                    <FontAwesomeIcon icon={faHouse} /> Home/
                    {subCategories && subCategories.length > 0 ? subCategories[0].cname : 'Default Category Name'}
                </h6>

                <div className="container">
                    <div className="row">
                        {!displayingProducts && subCategories && subCategories.length > 0 ? (
                            subCategories.map((subCategory: SubCategory) => (
                                <div key={subCategory.id} className="col-lg-1-5 col-md-2 col-sm-6 col-xs-3 mb-4">
                                    <div className="card2"
                                        onClick={() => handleSeeAllClick(subCategory)}
                                    >
                                        <div
                                            className="card2-img-wrapper"

                                        >

                                            <img
                                                className="card2-img-top"
                                                src={`${API_BASE_URL_IMAGE}/${subCategory.image}`}
                                                alt={`subcategory ${subCategory.id}`}
                                            />
                                            <h5 className="card2-title">{subCategory.name}</h5>


                                        </div>
                                        <div className="card2-title-overlay">
                                            <h5 className="card2-title">{subCategory.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Container>

                                <Row>
                                    {products.map((item, index) => (
                                        <Card key={index} onClick={() => handleProductDetails(item.id)}>
                                            <Img
                                                src={`${API_BASE_URL_IMAGE}/${item.image}`}
                                                alt={item.productName}
                                            />
                                            <CardBody>
                                                <CardTitle>{item.productName}</CardTitle>
                                                <CardPrice>৳{item.pricePerUnit}</CardPrice>
                                            </CardBody>
                                            <LoadMoreBtn onClick={() => handleProductDetails(item.id)}>
                                                See Details
                                            </LoadMoreBtn>
                                        </Card>
                                    ))}
                                </Row>
                            </Container>

                        )}
                        {productError && <p>{productError}</p>}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}
