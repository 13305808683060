import { useNavigate } from 'react-router-dom';
import { useGetCategories } from './api/categoryApi'; // Adjust the path as needed
import '../assets/css/categories.css';
import { API_BASE_URL_IMAGE } from '../config';
import { Spinner } from 'react-bootstrap';

export default function AllCategories() {
    const navigate = useNavigate();
    const { data: categories = [], isLoading, error } = useGetCategories();

    const handleCategoryClick = (categoryId: string) => {
        navigate(`/product-category/${categoryId}`); // Navigate to subcategories page
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <p>Loading categories...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <p>There was an error loading the categories. Please try again later.</p>
            </div>
        );
    }

    

    return (
        <div className="container">
            <h4 >Categories</h4>
            <div className="row">
                {categories.map((category: any) => (
                    <div key={category.id} className="col-lg-1-5 col-md-2 col-sm-6 col-xs-3 mb-4">
                        <div className="card2">
                            <div 
                                className="card2-img-wrapper"
                                onClick={() => handleCategoryClick(category.id)} // Make the image wrapper clickable
                            >
                                <img
                                    className="card2-img-top"
                                    src={`${API_BASE_URL_IMAGE}/${category.image}`}
                                    alt={`category ${category.name}`}
                                />
                                <h5>{category.name}</h5>
                            </div>

                            <div className="card2-title-overlay">
                                <h5 className="card2-title">{category.name}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    
}
