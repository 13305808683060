import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useAppSelector } from './hooks/useLogin';
import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import JustForYou from './JustForYou';
import PhoneFooter from './PhoneFooter';
import useViewport from './hooks/useViewport';

interface CartItemType {
  id: string;
  productImage: string;
  productName: string;
  productPrice: string;
  productQuantity: string;
  productId: string;
  productDescription: string;
}

interface CartSummaryType {
  itemCount: number;
  subtotal: number;
  total: number;
  totalQuantity: number;
}

const Cart: React.FC = () => {
  const viewportWidth = useViewport();
  const isMobile = viewportWidth < 768;
  const [isEmpty, setIsEmpty] = useState(false);
  const [cartItems, setCartItems] = useState<CartItemType[]>([]);
  const [summary, setSummary] = useState<CartSummaryType>({
    itemCount: 0,
    subtotal: 0,
    total: 0,
    totalQuantity: 0,
  });

  const { email } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      fetchCartItems(email);
    } else {
      navigate('/user/login');
    }
  }, [email, navigate]);

  const fetchCartItems = async (email: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/view-cart/${email}`);
      const { cart, message } = response.data;

      if (message === 'Cart is empty') {
        setIsEmpty(true);
        setCartItems([]);
        setSummary({ itemCount: 0, subtotal: 0, total: 0, totalQuantity: 0 });
      } else {
        setIsEmpty(false);
        const updatedCart = cart.map((item: CartItemType) => ({
          ...item,
          productQuantity: Number(item.productQuantity),
        }));
        updateCartSummary(updatedCart);
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  const updateCartSummary = (cart: CartItemType[]) => {
    const itemCount = cart.length;
    const totalQuantity = cart.reduce((total, item) => total + Number(item.productQuantity), 0);
    const subtotal = cart.reduce(
      (total, item) => total + Number(item.productPrice) * Number(item.productQuantity),
      0
    );
    setCartItems(cart);
    setSummary({ itemCount, subtotal, total: subtotal, totalQuantity });
  };

  const handleRemoveItem = async (productId: string) => {
    try {
      await axios.delete(`${API_BASE_URL}/user/remove-from-cart`, {
        params: { email, productId },
      });
      if (email) fetchCartItems(email);
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  return (
    <>
      <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
      <Container>
        {isEmpty ? (
          <EmptyCartMessage>

            Your cart is empty
            <p>Your cart is currently empty. Please add some products to your cart to proceed with checkout.</p>
            <Link to='/user-home' className="shop-now-button">Shop Now</Link>
            <JustForYou />


          </EmptyCartMessage>

        ) : (
          <Content>
            <CartItemsContainer>
              {cartItems.map((item) => (
                <CartItem key={item.id} item={item} onRemove={handleRemoveItem} />
              ))}
              {!isMobile && (
                <JustForYou />
              )}

            </CartItemsContainer>
            <SummaryContainer>
              <CartSummary summary={summary} />
            </SummaryContainer>
            {isMobile && (
              <JustForYou />
            )}
          </Content>
        )}
      </Container>
      <Footer />

      {isMobile && <PhoneFooter isLoggedIn={!!email} userHomeLink="/user-home" />}

    </>
  );
};

const CartItem: React.FC<{ item: CartItemType; onRemove: (id: string) => void }> = ({
  item,
  onRemove,
}) => (
  <ItemContainer>
    <Img src={`${API_BASE_URL_IMAGE}/${item.productImage}`} alt={item.productName} />
    <ItemDetails>
      <ItemTitle>{item.productName}</ItemTitle>
      <ItemPrice>৳{item.productPrice}</ItemPrice>
      <ActionContainer>
        <h4>Quantity</h4>
        <QuantityInput type="number" value={item.productQuantity} readOnly />
        <RemoveButton onClick={() => onRemove(item.productId)}>🗑️</RemoveButton>
      </ActionContainer>

    </ItemDetails>
  </ItemContainer>

);

const CartSummary: React.FC<{ summary: CartSummaryType }> = ({ summary }) => (
  <SummaryContent>
    <h4>Invoice and Contact Info</h4>
    <p>Subtotal ({summary.totalQuantity} items): ৳{summary.subtotal.toFixed(2)}</p>
    <p>Total: ৳{summary.total.toFixed(2)}</p>
    <Link to='/order'>PROCEED TO CHECKOUT</Link>
    <br></br>
    <br></br>
    <h4 className="mb-4 border-bottom pb-2" style={{ fontWeight: 'bold' }}>Return & Warranty</h4>
    <h5>Cancellation Before Shipment:</h5>
    <ul>
      <li>Timeframe: You can cancel your order within 24 hours of placing it, provided it has not been shipped yet.</li>
      <li>How to Cancel: Log in to your Interio account, go to your order history, and select the order you wish to cancel. Click on the “Cancel Order” button and follow the prompts.</li>
      <li>Confirmation: Once your cancellation request is processed, you will receive an email confirming the cancellation. Any payments made will be refunded to the original payment method within 7-10 business days.</li>
    </ul>
    <h5>Cancellation After Shipment:</h5>
    <ul>
      <li>Shipped Orders: If your order has already been shipped, it cannot be canceled. However, you may be eligible to return the product after it has been delivered, subject to our Return Policy.</li>
      <li>Refusal of Delivery: If you refuse delivery of an order that has already been shipped, the return shipping fees will be deducted from your refund.</li>
    </ul>
    <h5>Custom or Made-to-Order Products:</h5>
    <ul>
      <li>Custom Orders: Orders for custom or made-to-order products cannot be canceled once production has started. Please contact our customer service team as soon as possible if you need to make changes to a custom order.</li>
    </ul>
    <h5>Order Cancellation by Interio:</h5>
    <ul>
      <li>Right to Cancel: Interio reserves the right to cancel any order due to stock unavailability, payment issues, or if the order violates our Terms and Conditions.</li>
      <li>Notification: If your order is canceled by Interio, you will be notified via email, and a full refund will be processed to your original payment method.</li>
    </ul>
    <h5>Refund Processing:</h5>
    <ul>
      <li>Refund Timeframe: Refunds are typically processed within 7-10 business days. Depending on your bank or payment provider, it may take additional time for the refund to reflect in your account.</li>
      <li>Payment Method: Refunds will be issued to the original payment method used during purchase. If the payment was made via credit card, the refund will appear on your card statement.</li>
    </ul>
  </SummaryContent>
);

// Styled Components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 40px;
  }
`;

const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 3;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  @media (min-width: 768px) {
    width: 200px;
    height: auto;
    margin-right: 20px;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    flex: 1;
  }
`;

const ItemTitle = styled.h4`
  font-size: 1.2rem;
  margin: 0;
`;

const ItemPrice = styled.p`
  color: #f39c12;
  font-size: 1.5rem;
  margin: 0;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; // Space between QuantityInput and RemoveButton
  margin: 10px 0;
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.5rem;
`;

const SummaryContainer = styled.div`
  flex: 1;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const SummaryContent = styled.div`
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin: 5px 0;
  }
  a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
  }
  a:hover {
    background-color: #0056b3;
  }
`;

const EmptyCartMessage = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin: 50px 0;
`;

export default Cart;
