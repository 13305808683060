import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from './hooks/useLogin';
import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import Navbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import PhoneFooter from './PhoneFooter';
import useViewport from './hooks/useViewport';
import styled from 'styled-components';

// Define TypeScript interfaces for your data
interface Product {
  id: number;
  productName: string;
  productDescription: string;
  pricePerUnit: string;
  image: string;
  productCategory: string;
  productSubCategory: string;
}

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled.div`
  flex: 1 1 calc(50% - 1rem); /* 2 items per row on small screens */
  min-width: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 769px) {
    flex: 1 1 calc(25% - 1rem); /* 4 items per row on larger screens */
    max-width: 300px; /* Set a maximum width for cards */
  }
`;

const Img = styled.img`
  width: 100%;
  height: 200px; /* Set fixed height for images */
  object-fit: cover;
  transition: opacity 0.3s ease;
  border-bottom: 2px solid #eee;
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.h5`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const CardPrice = styled.p`
  font-size: 1.125rem;
  color: #f39c12;
  margin-bottom: 1rem;
`;

const LoadMoreBtn = styled.button`
  border: 2px solid orange;
  color: orange;
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: orange;
    color: white;
  }
`;

const TextCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const AllProduct: React.FC = () => {
  const { Id } = useParams<{ Id: string }>();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { email } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const viewportWidth = useViewport(); // Get the viewport width
  const isMobile = viewportWidth < 768;

  useEffect(() => {
    if (!Id) return;

    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/user/productSubCategoryId/${Id}`);
        setProducts(response.data.products); // Assuming response.data.products is an array of products
      } catch (err) {
        setError('No product found');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [Id]);

  if (loading) return <p>Loading...</p>;
  if (error) return (
    <div>
      <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
      <TextCenter>
        <h3>No Product Found</h3>
      </TextCenter>
      <Footer />
      {isMobile && <PhoneFooter isLoggedIn={!!email} userHomeLink={"/user-home"} />}
    </div>
  );

  const firstProduct = products[0];

  return (
    <div>
      <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
      <div className="product-page-container">
        <h4 className="mb-4" style={{ paddingLeft: '8%' }}>
          <FontAwesomeIcon icon={faHouse} />
          Home / {firstProduct?.productCategory || 'Category'} / {firstProduct?.productSubCategory || 'Product Sub Category'}
        </h4>
        <Container>
          <Row>
            {products.map((item) => (
              <Card key={item.id} onClick={() => navigate(`/product-details/${item.id}`)}>
                <Img src={`${API_BASE_URL_IMAGE}/${item.image}`} alt={item.productName} />
                <CardBody>
                  <CardTitle>{item.productName}</CardTitle>
                  <CardPrice>৳{item.pricePerUnit}</CardPrice>
                </CardBody>
                <LoadMoreBtn onClick={() => navigate(`/product-details/${item.id}`)}>See Details</LoadMoreBtn>
              </Card>
            ))}
          </Row>
        </Container>
      </div>
      <Footer />
      {isMobile && <PhoneFooter isLoggedIn={!!email} userHomeLink={"/user-home"} />}
    </div>
  );
};

export default AllProduct;
