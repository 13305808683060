import React, { useState, useCallback, useEffect } from 'react';
import '../assets/css/TryNavbar.css';
import Logo from '../assets/img/logo-white.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './hooks/useLogin';
import axios from 'axios';
import { clearAuth } from './redux/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faCartShopping, faClipboardCheck, faShieldHalved, faShippingFast, faSignOutAlt, faUndoAlt, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import Categories from './Categories';
import { Spinner } from 'react-bootstrap';
import { useGetCategories, useGetSubCategoriesByCategory } from './api/categoryApi';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
interface NavbarProps {
  isLoggedIn: boolean;
  userHomeLink: string;
  onLogout?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isLoggedIn, userHomeLink, onLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useAppSelector(state => state.auth);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  // Scroll event handler
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScrollTop) {
        setIsNavbarVisible(false); // Scrolling down
      } else {
        setIsNavbarVisible(true); // Scrolling up
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    if (onLogout) {
      onLogout();
    } else {
      try {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        await axios.get(`${API_BASE_URL}/user/logout`, { headers });
        dispatch(clearAuth());
        navigate('/user/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }
  };

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.trim() === '') {
      setSearchResults([]);
      setIsModalOpen(false);
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/user/searchproduct/${query}`);
      setSearchResults(response.data);
      setIsModalOpen(response.data.length > 0);
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const { data: categories, isLoading: isCategoriesLoading, isError: isCategoriesError } = useGetCategories();

  const { data: subCategories = [], isLoading: isSubCategoriesLoading, isError: isSubCategoriesError } = useGetSubCategoriesByCategory(activeCategory || '');

  const handleCategoryClick = (categoryId: string) => {
    // Toggle the subcategory dropdown visibility
    if (activeCategory === categoryId) {
      setActiveCategory(null); // Close dropdown if already open
    } else {
      setActiveCategory(categoryId); // Open dropdown for the selected category
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`navbar-container ${isNavbarVisible ? 'navbar-visible' : 'navbar-hidden'}`}>
      <div className='forAll'>
        <nav className="navbar navbar-expand-lg">
          <Link to={isLoggedIn ? userHomeLink : "/"}>
            <img src={Logo} alt="logo" width={150} />
          </Link>
          <div className="search-container">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className='search-phone'>
              <input
                className="form-control search-bar"
                type="search"
                placeholder="Search products..."
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div className='cart2' style={{ fontSize: '30px' }}>
              <Link className="nav-link" to={isLoggedIn ? "/cart" : "/user/login"}>
                <FontAwesomeIcon icon={faCartShopping} />
              </Link>
            </div>
          </div>
          <div className="search">
            <input
              className="form-control search-bar"
              type="search"
              placeholder="Search products..."
              aria-label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <ul className="navbar-nav" style={{ fontSize: '15px' }}>
            <li className="nav-item"><Link className="nav-link" to="/about-us">ABOUT US</Link></li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">HELP & SUPPORT</a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/shipping-delivery"><FontAwesomeIcon icon={faShippingFast} /> Shipping & Delivery</Link>
                <Link className="dropdown-item" to="/terms-conditions"><FontAwesomeIcon icon={faClipboardCheck} /> Terms and Conditions</Link>
                <Link className="dropdown-item" to="/refund-returns"><FontAwesomeIcon icon={faUndoAlt} /> Refund and Return Policy</Link>
                <Link className="dropdown-item" to="/privacy"><FontAwesomeIcon icon={faShieldHalved} /> Privacy Policy</Link>
                <Link className="dropdown-item" to="/cancellation"><FontAwesomeIcon icon={faUndoAlt} /> Cancellation Policy</Link>
              </div>
            </li>
            <li className="nav-item"><Link className="nav-link" to="/contact-us">CONTACT US</Link></li>

            {isLoggedIn ? (
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownUser" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <FontAwesomeIcon icon={faCircleUser} className="user-icon" style={{ color: 'white' }} />
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownUser">
                  <Link className="dropdown-item" to="/user/profile"><FontAwesomeIcon icon={faCircleUser} /> My Account</Link>
                  <Link className="dropdown-item" to="/myorder"><FontAwesomeIcon icon={faClipboardCheck} /> My Orders</Link>
                  <div className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                  </div>
                </div>
              </li>
            ) : (
              <>
                <li className="nav-item"><Link className="nav-link" to="/user/login">LOGIN</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/user/register">SIGN UP</Link></li>
              </>
            )}

            <li className="nav-item" style={{ fontSize: '20px' }}>
              <Link className="nav-link" to={isLoggedIn ? "/cart" : "/user/login"}>
                <FontAwesomeIcon icon={faCartShopping} />
              </Link>
            </li>
          </ul>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="categories-phone">
              <div className="category-container1">
                {categories?.map((category: any) => (
                  <div
                    key={category.id}
                    onClick={() => handleCategoryClick(category.id)}
                    className={`category-item1 ${activeCategory === category.id ? 'active' : ''}`}
                  >
                    <Link to={`/product-category/${category.id}`} className="category-link">
                      {category.name}
                      


                    </Link>
                    {activeCategory === category.id && (
                      <div className="subcategories-dropdown">
                        {isSubCategoriesLoading ? (
                          <div>Loading...</div>
                        ) : isSubCategoriesError ? (
                          <div>
                            <Spinner animation="border" role="status" size="sm">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                            .
                          </div>
                        ) : subCategories.length > 0 ? (
                          subCategories.map((subCategory: any) => (
                            <Link key={subCategory.id} to={`/allproduct/${subCategory.id}`} className="subcategory-link">
                              {subCategory.name}
                            </Link>
                          ))
                        ) : (
                          <div>No subcategories available.</div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </nav>
        <div className="categories-container">
          <Categories />
        </div>

        {isModalOpen && (
          <div className="modal fade show" style={{ display: 'block' }} tabIndex={-1} role="dialog" aria-labelledby="searchResultsModalLabel" aria-hidden={!isModalOpen}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="searchResultsModalLabel">Search Results</h5>
                  <button type="button" className="close" aria-label="Close" onClick={handleModalClose}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {searchResults.length > 0 ? (
                    <ul className="list-group">
                      {searchResults.map((result, index) => (
                        <li key={index} className="list-group-item d-flex align-items-center">
                          <Link to={`/product-details/${result.id}`} className="search-result-link d-flex align-items-center">
                            <img src={`${API_BASE_URL_IMAGE}/${result.image}`} alt={result.productName} width={80} height={80} className="mr-2" />
                            <div>
                              <p className="mb-1">{result.productName}</p>
                              <p className="mb-0 text-primary">{result.pricePerUnit}</p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No results found.</p>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
