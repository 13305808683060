import img1 from '../assets/img/Group92.png';

export default function WeOffer() {
  return (
    <div className="we-offer-container">
      <div className="content">
        {/* Image Section */}
        <div className="col-img">
          <img src={img1} alt="img1" />
        </div>

        {/* Text Section */}
        <div className="col-text">
          <h2>What We Offer</h2>
          <br/>
          <br/>
          <p>
            Our extensive catalog features an array of interior products, including furniture, lighting, decor,
            textiles, and more. Whether you're looking to revamp your living room, update your bedroom, or add a touch
            of elegance to your office, Interio has something for every space and budget. Each item is selected for its
            quality, craftsmanship, and design, ensuring that you receive only the best.
          </p>
        </div>
      </div>

      {/* Inline styles for responsive design */}
      <style>
        {`
          .we-offer-container {
            background-color: #F9F9F9;
            background-image: url('../img/Group91.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            padding: 10% 5%;
            color: #333;
            width: 100vw;
            box-sizing: border-box;
          }

          .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
          }

          .col-img {
            flex: 1;
            min-width: 300px; /* Prevent image from being too small */
          }

          .col-img img {
            max-width: 100%;
            height: auto;
            display: block;
          }

          .col-text {
            flex: 1;
            padding: 0 1rem;
          }

          .col-text h2 {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 1rem;
          }

          .col-text p {
            font-size: 1rem;
            text-align: justify;
          }

          @media (max-width: 767px) {
            .content {
              flex-direction: column;
              align-items: stretch;
            }

            .col-img, .col-text {
              width: 100%;
              margin-bottom: 1rem; /* Space between image and text */
            }

            .col-img img {
              width: 100%;
              height: auto;
              display: block;
            }

            .col-text h2 {
              font-size: 1.25rem;
              text-align: center;
            }

            .col-text p {
              font-size: 0.875rem;
              line-height: 1.5;
            }
          }
        `}
      </style>
    </div>
  );
}
