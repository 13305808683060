import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useAppSelector } from './hooks/useLogin';
import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_IMAGE } from '../config';
import DOMPurify from 'dompurify';
import Footer from './Footer';
import { success_url, fail_url, cancel_url, sandboxUrl, signature_key, store_id, merchant_id } from '../config';
import { useNavigate } from 'react-router-dom';
import useViewport from './hooks/useViewport';
import PhoneFooter from './PhoneFooter';

interface CartItemType {
    id: string;
    productImage: string;
    productName: string;
    productPrice: string;
    productQuantity: string;
    productId: string;
    productDescription: string;
}

interface CartSummaryType {
    itemCount: number;
    subtotal: number;
    total: number;
    totalQuantity: number;
    productIds: number[]; // Make sure to include this in the type definition
}


export default function Order() {
    const [isEmpty, setIsEmpty] = useState(false);
    const [cartItems, setCartItems] = useState<CartItemType[]>([]);
    const [summary, setSummary] = useState<CartSummaryType>({
        itemCount: 0,
        subtotal: 0,
        total: 0,
        totalQuantity: 0,
        productIds: [], // Initialize with an empty array
    });

    const { email } = useAppSelector((state) => state.auth);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [deliveryOption, setDeliveryOption] = useState('Online Payment');
    const navigate = useNavigate();
    const [extraAmount, setExtraAmount] = useState<number | ''>('');
    const viewportWidth = useViewport();
    const [isAgreed, setIsAgreed] = useState(false); // Add this state for the checkbox

    const isMobile = viewportWidth < 768;

    useEffect(() => {
        if (!email) {
            navigate('/user/login');
        }
        
        if (email) {
            fetchCartItems(email);
        }
    }, [email, navigate]);

    const fetchCartItems = async (email: string) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/user/view-cart/${email}`);
            const { cart, message } = response.data;
    
            if (message === 'Cart is empty') {
                setIsEmpty(true);
                setCartItems([]);
                setSummary({
                    itemCount: 0,
                    subtotal: 0,
                    total: 0,
                    totalQuantity: 0,
                    productIds: [], // Initialize with an empty array
                });
            } else {
                setIsEmpty(false);
                const updatedCart = cart.map((item: CartItemType) => ({
                    ...item,
                    productQuantity: Number(item.productQuantity),
                }));
                updateCartSummary(updatedCart);
            }
        } catch (error) {
            console.error('Error fetching cart items:', error);
        }
    };

    const updateCartSummary = (cart: CartItemType[]) => {
        const itemCount = cart.length;
        const totalQuantity = cart.reduce((total, item) => total + Number(item.productQuantity), 0);
        const subtotal = cart.reduce(
            (total, item) => total + Number(item.productPrice) * Number(item.productQuantity),
            0
        );
        const productIds = cart.map(item => Number(item.productId)); // Extract product IDs

        setCartItems(cart);
        setSummary({
            itemCount,
            subtotal,
            total: subtotal,
            totalQuantity,
            productIds,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!isAgreed) {
            alert('You must agree to the terms and conditions.');
            return;
        }
        const finalAmount = deliveryOption === 'Cash On Delivery'
            ? Math.max(summary.totalQuantity*180, extraAmount || summary.totalQuantity*180)
            : summary.total;

        const data = {
            cus_name: name,
            cus_email: email || '',
            cus_phone: phone,
            amount: finalAmount,
            store_id: store_id || '',
            merchant_id: merchant_id || '',
            signature_key: signature_key || '',
            tran_id: Math.floor(Math.random() * 10000),
            success_url: success_url || '',
            fail_url: fail_url || '',
            cancel_url: cancel_url || '',
            currency: "BDT",
            desc: "Payment for order",
            opt_a: city,
            opt_b: address,
            opt_c: deliveryOption,

            type: "json"
        };
        let uploadAbleData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            uploadAbleData.append(key, value?.toString());
        });

        try {
            const response = await axios.post(sandboxUrl, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.payment_url) {
                window.location.href = response.data.payment_url;
            }
        } catch (error) {
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <div style={{ position: 'sticky', top: 0, zIndex: 100, backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                <Navbar isLoggedIn={!!email} userHomeLink="/user-home" />
            </div>

            <div style={{ padding: '20px', margin: '0 auto', maxWidth: '1200px' }}>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '20px' }}>
                    <div style={{ flex: 1, backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px' }}>
                        <h3 style={{ borderBottom: '2px solid #007BFF', paddingBottom: '10px', marginBottom: '20px' }}>Shipping & Billing Information</h3>
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
                            <label htmlFor="name" style={{ fontWeight: 'bold' }}>Name:</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Enter Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                            />
                            <label htmlFor="phone" style={{ fontWeight: 'bold' }}>Phone:</label>
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="Enter Your Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                            />
                            <label htmlFor="districts" style={{ fontWeight: 'bold' }}>City:</label>
                            <select
                                id="districts"
                                name="districts"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                                style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                            >
                                <option value="">Select District</option>
                                <option value="Bandar">Bandar</option>
                                <option value="Bandarban">Bandarban</option>
                                <option value="Barisal">Barisal</option>
                                <option value="Bhola">Bhola</option>
                                <option value="Brahmanbaria">Brahmanbaria</option>
                                <option value="Chandpur">Chandpur</option>
                                <option value="Chattogram">Chattogram</option>
                                <option value="Chuadanga">Chuadanga</option>
                                <option value="Dhaka">Dhaka</option>
                                <option value="Dinajpur">Dinajpur</option>
                                <option value="Faridpur">Faridpur</option>
                                <option value="Feni">Feni</option>
                                <option value="Gaibandha">Gaibandha</option>
                                <option value="Gazipur">Gazipur</option>
                                <option value="Gopalganj">Gopalganj</option>
                                <option value="Habiganj">Habiganj</option>
                                <option value="Jamalpur">Jamalpur</option>
                                <option value="Jashore">Jashore</option>
                                <option value="Jatiyo">Jatiyo</option>
                                <option value="Jhalokati">Jhalokati</option>
                                <option value="Jhinaidah">Jhinaidah</option>
                                <option value="Joypurhat">Joypurhat</option>
                                <option value="Khagrachari">Khagrachari</option>
                                <option value="Khulna">Khulna</option>
                                <option value="Kishoreganj">Kishoreganj</option>
                                <option value="Kotchandpur">Kotchandpur</option>
                                <option value="Lalmonirhat">Lalmonirhat</option>
                                <option value="Madaripur">Madaripur</option>
                                <option value="Magura">Magura</option>
                                <option value="Manikganj">Manikganj</option>
                                <option value="Meherpur">Meherpur</option>
                                <option value="Moulvibazar">Moulvibazar</option>
                                <option value="Mymensingh">Mymensingh</option>
                                <option value="Naogaon">Naogaon</option>
                                <option value="Narail">Narail</option>
                                <option value="Narsingdi">Narsingdi</option>
                                <option value="Natore">Natore</option>
                                <option value="Netrokona">Netrokona</option>
                                <option value="Nilphamari">Nilphamari</option>
                                <option value="Noakhali">Noakhali</option>
                                <option value="Panchagarh">Panchagarh</option>
                                <option value="Patuakhali">Patuakhali</option>
                                <option value="Pirojpur">Pirojpur</option>
                                <option value="Rajbari">Rajbari</option>
                                <option value="Rajshahi">Rajshahi</option>
                                <option value="Rangamati">Rangamati</option>
                                <option value="Rangpur">Rangpur</option>
                                <option value="Satkhira">Satkhira</option>
                                <option value="Shariatpur">Shariatpur</option>
                                <option value="Sherpur">Sherpur</option>
                                <option value="Sirajganj">Sirajganj</option>
                                <option value="Sunamganj">Sunamganj</option>
                                <option value="Sylhet">Sylhet</option>
                                <option value="Tangail">Tangail</option>
                                <option value="Thakurgaon">Thakurgaon</option>
                            </select>
                            <label htmlFor="address" style={{ fontWeight: 'bold' }}>Address:</label>
                            <textarea
                                name="address"
                                id="address"
                                placeholder="Enter Your Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                                style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                            ></textarea>
                            <label htmlFor="delivery-option" style={{ fontWeight: 'bold' }}>Delivery Option:</label>
                            <select
                                id="delivery-option"
                                name="delivery-option"
                                value={deliveryOption}
                                onChange={(e) => {
                                    setDeliveryOption(e.target.value);
                                    if (e.target.value !== 'Cash On Delivery') {
                                        setExtraAmount('');
                                    }
                                }}
                                required
                                style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                            >
                                <option value="Online Payment">Online Payment</option>
                                <option value="Cash On Delivery">Cash On Delivery</option>
                            </select>
                            {deliveryOption === 'Cash On Delivery' && (
                                <>
                                    <label htmlFor="extra-amount" style={{ fontWeight: 'bold' }}>Extra Amount (minimum ৳{summary.totalQuantity*180}):</label>
                                    <input
                                        type="number"
                                        id="extra-amount"
                                        name="extra-amount"
                                        min={summary.totalQuantity*180}
                                        value={extraAmount}
                                        onChange={(e) => setExtraAmount(Number(e.target.value))}
                                        placeholder="Enter Extra Amount"
                                        style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}
                                    />
                                </>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    id="terms"
                                    name="terms"
                                    checked={isAgreed}
                                    onChange={(e) => setIsAgreed(e.target.checked)}
                                    required
                                    style={{ marginRight: '10px' }}
                                />
                                <label htmlFor="terms">
                                    I agree to the <a href="/terms-conditions" target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>Terms and Conditions</a>.
                                </label>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <button
                                    type="submit"
                                    style={{ padding: '10px 20px', border: 'none', borderRadius: '4px', backgroundColor: '#007BFF', color: '#fff', fontSize: '16px', cursor: 'pointer' }}
                                >
                                    Proceed to Payment
                                </button>
                            </div>
                        </form>
                    </div>
                    <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                        <h3 style={{ borderBottom: '2px solid #007BFF', paddingBottom: '10px', marginBottom: '20px' }}>Order Summary</h3>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {cartItems.map((item) => (
                                <div key={item.id} style={{ display: 'flex', marginBottom: '20px', borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
                                    <img
                                        src={`${API_BASE_URL_IMAGE}/${item.productImage}`}
                                        alt={item.productName}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '15px' }}
                                    />
                                    <div>
                                        <h4 style={{ margin: '0 0 10px', fontSize: '18px' }}>{item.productName}</h4>

                                        <div style={{ fontSize: '16px', color: '#555' }}>
                                            <span style={{ fontWeight: 'bold' }}>৳{item.productPrice}</span>
                                            <span style={{ marginLeft: '10px' }}>Quantity: {item.productQuantity}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ borderTop: '2px solid #007BFF', paddingTop: '10px', marginTop: '20px' }}>
                            <p style={{ fontSize: '16px' }}>Total Items: {summary.totalQuantity}</p>
                            <p style={{ fontSize: '16px' }}>Subtotal: ৳{summary.subtotal}</p>
                            <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>Total: ৳{summary.total}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <div style={{ marginTop: '20px' }}>
                {isMobile && <PhoneFooter isLoggedIn={!!email} userHomeLink={"/user-home"} />}
            </div>
        </div>
    );
}
