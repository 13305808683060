import React, { useState } from 'react';
import styled from 'styled-components';
import AccordionSection from './AccordionSection';

const WhyChooseInterio: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const accordionItems = [
    { title: "Quality and Durability", content: "We prioritize quality and durability in all our products, ensuring that your investment lasts for years to come." },
    { title: "Exceptional Service", content: "We provide exceptional service tailored to your needs." },
    { title: "Variety and Style", content: "A wide range of styles to suit your preferences." },
    { title: "Inspiration and Ideas", content: "Get inspired with our innovative designs and ideas." },
  ];

  // Handle the toggling of sections
  const handleToggle = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index); // Close if the same index is clicked
  };

  return (
    <Container>
      <Heading>Why Choose Interio?</Heading>

      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            <div className="col-md-6">
              <br />
              <AccordionSection
                items={accordionItems.slice(0, 2)} // First half of items
                activeIndex={activeIndex} // Use the same activeIndex
                startIndex={0} // Starting index for this section
                onToggle={handleToggle}
              />
            </div>
            <div className="col-md-6">
              <br />
              <AccordionSection
                items={accordionItems.slice(2)} // Second half of items
                activeIndex={activeIndex} // Use the same activeIndex
                startIndex={2} // Starting index for this section
                onToggle={handleToggle}
              />
            </div>
          </div>
        </div>
      </div>
      <CommitmentSection>
        <Heading>Our Commitment</Heading>
        <br />
        <br />
        <Card>
          <p className="text-center mb-0" style={{ color: 'black' }}>
            At Interio, we are committed to sustainability and ethical sourcing. We strive to work with suppliers who share our values and adhere to fair trade practices. Our goal is to offer products that are not only beautiful and functional but also environmentally responsible.
          </p>
        </Card>
      </CommitmentSection>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  background-color: rgb(242, 238, 232);
  padding: 40px;
  margin: 0 auto;
`;

const Heading = styled.h2`
  position: relative;
  display: inline;
  margin-bottom: 1rem;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    height: 2px;
    width: 50%;
    background-color: orange;
  }
`;

const CommitmentSection = styled.div`
  text-align: center;
  background-color: rgb(242, 238, 232);
  padding: 40px;
  margin: 0 auto;
`;

const Card = styled.div`
  border: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: white;
  padding: 20px;
`;

export default WhyChooseInterio;
