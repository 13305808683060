import React, { useState, useCallback } from 'react';
import { useGetCategories, useGetSubCategoriesByCategory } from './api/categoryApi';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../assets/css/TCategories.css'; // Add a CSS file for custom styles
const Categories: React.FC = React.memo(() => {
  const { data: categories, isLoading: isCategoriesLoading, isError: isCategoriesError } = useGetCategories();
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [showAllCategories, setShowAllCategories] = useState(false); // Toggle for mobile view
  const [showSubcategories, setShowSubcategories] = useState<string | null>(null); // Handle toggle for subcategories

  const handleMouseEnter = useCallback((categoryId: string) => {
    setActiveCategory(categoryId);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveCategory(null);
  }, []);

  const handleCategoryClick = (categoryId: string) => {
    if (showSubcategories === categoryId) {
      setShowSubcategories(null); // Close dropdown if already open
    } else {
      setShowSubcategories(categoryId); // Toggle subcategories
    }
  };

  const { data: subCategories = [], isLoading: isSubCategoriesLoading, isError: isSubCategoriesError } = useGetSubCategoriesByCategory(activeCategory || '');

  if (isCategoriesLoading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (isCategoriesError) {
    return (
      <div className="error-container">
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="categories-container">
      {/* Mobile Dropdown for All Categories */}
    

      {/* Desktop View */}
      <div className="categories-menu">
        {categories?.map((category: any) => (
          <div
            key={category.id}
            onMouseEnter={() => handleMouseEnter(category.id)}
            onMouseLeave={handleMouseLeave}
            className="category-item"
          >
            <Link to={`/product-category/${category.id}`} className="category-link">
              {category.name}
            </Link>
            {activeCategory === category.id && (
              <div className="subcategories-dropdown1">
                {isSubCategoriesLoading ? (
                  <div>Loading...</div>
                ) : isSubCategoriesError ? (
                  <div>
                    <Spinner animation="border" role="status" size="sm">
                      <span className="sr-only">Loading...</span>
                    </Spinner>

                    .</div>
                ) : subCategories.length > 0 ? (
                  subCategories.map((subCategory: any) => (
                    <Link key={subCategory.id} to={`/allproduct/${subCategory.id}`} className="subcategory-link">
                      {subCategory.name}
                    </Link>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Categories;
