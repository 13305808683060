import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaUserCircle, FaSignInAlt } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap'; // Import Dropdown from react-bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faClipboardCheck, faUndoAlt, faShieldHalved, faCircleUser, faSignOutAlt, faPhoneAlt, faInfoCircle, faHeadset } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/PhoneFooter.css'; // Import the CSS for styling
import { API_BASE_URL } from '../config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useAppSelector } from './hooks/useLogin';
import { clearAuth } from './redux/authSlice';
interface NavbarProps {
    isLoggedIn: boolean;
    userHomeLink: string;
    onLogout?: () => void;
}

const PhoneFooter: React.FC<NavbarProps> = ({ isLoggedIn, userHomeLink, onLogout }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useAppSelector(state => state.auth);

    const handleLogout = async () => {
        if (onLogout) {
            onLogout();
        } else {
            try {
                const headers = token ? { Authorization: `Bearer ${token}` } : {};
                await axios.get(`${API_BASE_URL}/user/logout`, { headers });
                dispatch(clearAuth());
                navigate('/user/login');
            } catch (error) {
                console.error('Error during logout:', error);
            }
        }
    };

    return (
        <footer className="footer1">
            <div className="footer-item1">
                <Link to="/">
                    <FaHome className="footer-icon1" />
                    <br></br>
                    <h6>Home</h6>
                </Link>
            </div>
            <div className="footer-item1">
                <Link to="/about-us">
                    <FontAwesomeIcon icon={faInfoCircle} className="footer-icon1" />
                    <br></br>
                    <h6>About</h6>

                </Link>
            </div>

            <Dropdown className="footer-item1">
                <Dropdown.Toggle className="footer-icon1" variant="link" id="dropdown-custom-components">
                    <FontAwesomeIcon icon={faHeadset} className="footer-icon1" />
                    <br />
                    <h6>Help</h6>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/shipping-delivery">
                        <FontAwesomeIcon icon={faShippingFast} /> Shipping & Delivery
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/terms-conditions">
                        <FontAwesomeIcon icon={faClipboardCheck} /> Terms and Conditions
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/refund-returns">
                        <FontAwesomeIcon icon={faUndoAlt} /> Refund and Return Policy
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/privacy">
                        <FontAwesomeIcon icon={faShieldHalved} /> Privacy Policy
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/cancellation">
                        <FontAwesomeIcon icon={faUndoAlt} /> Cancellation Policy
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <div className="footer-item1">
                <Link to="/contact-us">
                    <FontAwesomeIcon icon={faPhoneAlt} className="footer-icon1" />
                    <br></br>
                    <h6>Contact</h6>

                </Link>
            </div>

            {isLoggedIn ? (
                <>
                    <Dropdown className="footer-item1">
                        <Dropdown.Toggle className="footer-icon1" variant="link" id="dropdown-custom-components">
                            <FaUserCircle className="footer-icon1" />
                            <br></br>
                            <h6>Account</h6>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/user/profile">
                                <FontAwesomeIcon icon={faCircleUser} /> My Account
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/myorder">
                                <FontAwesomeIcon icon={faClipboardCheck} /> My Orders
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/refund-returns">
                                <div className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                                </div>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>


                </>
            ) : (
                <>
                    <div className="footer-item1">
                        <Link to="/user/login">
                            <FaSignInAlt className="footer-icon1" />
                            <br></br>
                            <h6>Login</h6>
                        </Link>
                    </div>
                </>
            )}
        </footer>
    );
};

export default PhoneFooter;
