import { useEffect } from 'react';
import Footer from './Footer';
import AllCategories from './AllCategories';
import WhyChooseInterio from './WhyChooseInterio';
import Steps from './Steps';
import HeroSection from './HeroSection';
import JustForYou from './JustForYou';
import BestSellingProducts from './BestSellingProducts';
import { useAppSelector } from './hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import PhoneFooter from './PhoneFooter';
import useViewport from './hooks/useViewport';

export default function UserHome() {
  const { email } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const viewportWidth = useViewport(); // Get the viewport width

  const isMobile = viewportWidth < 768;
  useEffect(() => {
    if (!email) {
      navigate('/user/login');
    }
  }, [email, navigate]);

  return (
    <div >
     
        <Navbar
          isLoggedIn={true}
          userHomeLink="/user-home"
        />
       
      
      
        <HeroSection />
        <AllCategories />
        <JustForYou />
        <BestSellingProducts />
        <WhyChooseInterio />
     
      <Steps />
      <Footer />
      <br></br>
      <br></br>
      {isMobile && <PhoneFooter isLoggedIn={true} userHomeLink={'/user-home'} />}

      
    </div>
  );
}
